.ui {
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}

[class*='Card-Video-Style'].ui.card, [class*='Card-Video-Style'].ui.cards>[class*='Card-Video-Style'].card {
    border-radius: 0;
    margin-top: 1em;
    font-family: inherit;
    margin-left: 0.2857142857142857em!important;
    margin-right: 0.2857142857142857em!important;
}

.ui.three.cards {
    margin-left: 1em;
    margin-right: 1em;
}

.ui.grid, .ui.grid>.row>.column {
    padding-left: .1em;
    padding-right: .1em;
    margin-bottom: 3em;
}

.ui.search .prompt {
    border-radius: 0;
    font-family: inherit;
}

.ui.container {
    width:100%!important;
    font-family: inherit;
}

.ui.card .image {
    padding: 0;
    border-radius: 0;
}

.ui.card>:first-child, .ui.cards>.card>:first-child {
    border-radius: 0!important;
    font-family: inherit;
}

.ui.card>.extra, .ui.cards>.card>.extra {
    background: linear-gradient(30deg, rgba(27,27,27,1), rgba(27,27,27,0.2));
    border-radius: 0!important;
    font-family: inherit;
    border-top: 0em solid black!important;
}


.ui.card:hover>.extra {
    background: #1b1b1b!important;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
    font-family: inherit;
}

.ui.cards a.card, .ui.link.card, .ui.link.cards .card, a.ui.card {
    margin-left: 0;
    margin-right: 0;
}

.ui.secondary.pointing.menu .item {
    outline: none!important;
}