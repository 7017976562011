.App {
    text-align: center;
    color:#1b1b1b!important;
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #1b1b1b!important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #e5dfd9;
    border-radius: 0;
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ui {
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}

.ui.inverted.ms-background {
    background-color: #1b1b1b !important;
    color: #e5dfd9 !important;
}

.ui.inverted.menu .item {
    color: #e5dfd9 !important;
    background: #1b1b1b!important;
    border-radius: 0;
}

.ui.inverted.menu .item:hover {
    color: white!important;
    background: #1b1b1b !important;
    border-radius: 0;
}

.ui.inverted.menu .item > a {
    color: #e5dfd9 !important;
    position: inherit !important;
    border-radius: 0;
}

.ui.inverted.menu .active.item > a {
    color: white!important;
}

.ui.inverted.menu .item > a:hover {
    color: white!important;
    position: inherit !important;
    border-radius: 0;
}

.ui.menu {
    border-radius: 0;
}


.ui.inverted.pointing.menu .active.item:after {
    background: white!important;
}

.ui.button {
    color: #e5dfd9 !important;
    background-color: #1b1b1b !important;
    font-weight: 400;
}

.ui.button:hover {
    color: #e5dfd9 !important;
    background-color: #313131!important;
}


.ui.embed > .icon:before {
    color: rgba(255, 255, 255, 0.7);
}

.logo {
height: 100px;
}

/*per non mostrare resultati*/
.ui.search > .results {
    display: none !important;
}

.ui.grid>.row>[class*="center aligned"].column.column, .ui.grid>[class*="center aligned"].column.column, .ui.grid>[class*="center aligned"].row>.column, .ui[class*="center aligned"].grid>.column, .ui[class*="center aligned"].grid>.row>.column{
    height:fit-content;
}


