.ui {
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}


.ui.grid {
    margin: 0!important;
    padding: 0!important;
    font-family: inherit;
    text-align: center!important;
}


.ui.grid>.row>[class*="center aligned"].column.column, .ui.grid>[class*="center aligned"].column.column, .ui.grid>[class*="center aligned"].row>.column, .ui[class*="center aligned"].grid>.column, .ui[class*="center aligned"].grid>.row>.column {
    text-align: -webkit-center!important;
}


.ui.header {
    font-family: inherit;
    padding: 0;
}

.ui.input>input {
    border-radius: 0;
    font-family: inherit;
}

.ui.action.input:not([class*="left action"]) > .button:last-child, .ui.action.input:not([class*="left action"]) > .buttons:last-child > .button, .ui.action.input:not([class*="left action"]) > .dropdown:last-child {
    border-radius: 0;
    font-family: inherit;
}

.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
    font-weight: normal;
}

.ui.cards a.card:hover, .ui.link.card:hover, .ui.link.cards .card:hover, a.ui.card:hover {
    box-shadow: 0 1px 3px 0 #313131, 0 0 0 1px #f5f5f5!important;
}

.ui.raised.card, .ui.raised.cards>.card {
    box-shadow: 0 .1em 1.5em 0 #313131, 0 0 0 .1em #f5f5f5!important;
    background-color: #f5f5f5!important;
}

.ui.card {
    border-radius: 0!important;
    background-color: #e7e7e7!important;
}