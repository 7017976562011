
/* GucciSans webfont (new-bulletproof) */
@font-face {
    font-family: 'GucciSans-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('GucciSans'),
    url('./GucciSans/GucciSans-Bold.woff') format('woff'), /* Modern Browsers */
    url('./GucciSans/GucciSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./GucciSans/GucciSans-Bold.woff2') format('woff2'),
    url('./GucciSans/GucciSans-Bold.otf') format('otf');
}

/* Futura-Medium webfont (new-bulletproof) */
@font-face {
    font-family: 'GucciSans-Bold-Italic';
    font-style: normal;
    font-weight: 700;
    src: local('GucciSans'),
    url('./GucciSans/GucciSans-BoldItalic.woff') format('woff'), /* Modern Browsers */
    url('./GucciSans/GucciSans-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./GucciSans/GucciSans-BoldItalic.woff2') format('woff2'),
    url('./GucciSans/GucciSans-BoldItalic.otf') format('otf');
}

@font-face {
    font-family: 'GucciSans-Book';
    font-style: normal;
    font-weight: 400;
    src: local('GucciSans'),
    url('./GucciSans/GucciSans-Book.woff') format('woff'), /* Modern Browsers */
    url('./GucciSans/GucciSans-Book.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./GucciSans/GucciSans-Book.woff2') format('woff2'),
    url('./GucciSans/GucciSans-Book.otf') format('otf');
}

@font-face {
    font-family: 'GucciSans-Book-Italic';
    font-style: normal;
    font-weight: 400;
    src: local('GucciSans'),
    url('./GucciSans/GucciSans-BookItalic.woff') format('woff'), /* Modern Browsers */
    url('./GucciSans/GucciSans-BookItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./GucciSans/GucciSans-BookItalic.woff2') format('woff2'),
    url('./GucciSans/GucciSans-BookItalic.otf') format('otf');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
code {
    font-family: 'GucciSans', 'GucciSans-Italic', 'Futura-Medium';
}
*/