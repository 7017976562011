.ui {
    font-family: 'GucciSans-Book', 'GucciSans-Book-Italic', 'GucciSans-Bold-Italic', 'GucciSans-Bold', sans-serif!important;
}

.ui.button {
    border-radius:0;
    font-family: inherit;
}

.ui.segment {
    border-radius: 0;
    font-family: inherit;
}

.ui.form .field .ui.input input, .ui.form .fields .field .ui.input input{
    border-radius: 0;
    font-family: inherit;
}

.ui.form .inline.fields .field {
    padding: 0;
}

.ui.selection.dropdown {
    border-radius: 0;
    font-family: inherit;
}

.ui.form textarea, .ui.form .field .ui.input textarea, .ui.form .fields .field .ui.input textarea {
    border-radius: 0;
    font-family: inherit;
}

.ui.container {
    font-family: inherit;
    border-radius: 0;
}

.ui.label {
    font-family: inherit;
    border-radius: 0;
}

.ui.required {
    font-family: inherit;
    border-radius: 0;
}

i.bordered.icon {
    box-shadow: 0 0 0 0.1em #e5dfd9 inset;
}

.visible.transition {
    border-radius:0;
    font-family: inherit;
}

.ui.negative.message {
    border-radius: 0;
    background-color: #fff6f6!important;
    font-family: inherit;
}

.ui.message {
    border-radius: 0;
    background: #e7e7e7!important;
    box-shadow: none;
    border-color: #e5dfd9!important;
    border: 1px solid;
    font-family: inherit;
}

.ui.placeholder.segment {
    background: #e7e7e7!important;
    box-shadow: none;
    border-color: #e5dfd9!important;
    font-family: inherit;
}

.ui[class*="left action"].input>.button:first-child, .ui[class*="left action"].input>.buttons:first-child>.button, .ui[class*="left action"].input>.dropdown:first-child {
    border-radius: 0;
    font-family: inherit;
}
.react-datepicker-component .react-datepicker-input {
    position: relative;
    min-width: 150px;
    height: 36px;
    background: linear-gradient(#fff, #f2f4f7);
    border: none!important;
    border-radius: 4px;
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
    background: linear-gradient(#f0f3f8, #ffffff)!important;
    color: #313131!important;
    border-top: 1px solid #d9dee3!important;
    border-left: 1px solid #d9dee3!important;
    border-right: 1px solid #d9dee3!important;
}
.react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after {
    border-bottom-color: #d9dee3!important;
}