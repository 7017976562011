.ui.tabular.menu .active.item {
    border-radius: 0 !important;
}

.ui.button2 {
    color: #e5dfd9 !important;
    background-color: rgb(230, 101, 101) !important;
    font-weight: 400;
}

.ui.grid, .ui.grid>.row>.column {
    margin-bottom:inherit!important
}

.ui.vertical.menu {
    width: unset!important;
    position: fixed;
}

.ui.selection.active.dropdown .menu, .ui.upward.selection.dropdown.visible {
    border-radius: 0!important;
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    border-radius: 0;
}

.ui.form .inline.fields .field {
    align-self: flex-start;
}

.ui.form textarea:focus {
    border-radius: 0!important;
    border-color: #1b1b1b;
}

.ui.form input:focus {
    border-color: #1b1b1b!important;
    border-radius: 0!important;
    color: #1b1b1b!important;
}

.ui.modal>.actions {
    border-top: none!important;
    background-color: #e7e7e7!important;
}

.ui.modal>.header {
    border: none!important;
    background-color: #e7e7e7!important;
}


.ui.header {
    font-weight: 400;
    font-size: 2em;
}

.icon-rc-datepicker_calendar::before {
    color: #e7e7e7;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current {
    background-color: #f5f5f5;
    color: #1b1b1b;
    font-weight: 400;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected.current {
    box-sizing: content-box;
    color: #1b1b1b;
    background-color: #e7e7e7;
    border-color: #1b1b1b;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current:hover {
    background-color: #e7e7e7;
}

.ui.gridActionForms>.gridActionFormsRow {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: inherit;
    justify-content: inherit;
    -webkit-align-items: stretch;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 0.1rem;
    padding-bottom: 0.5rem;
}